import React, { useRef, useCallback } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Scrollspy from "react-scrollspy";
import { Link as ScrollLink } from "react-scroll";
import AutoAffix from "../../components/affix/AutoAffix";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { Link } from "../../components/link";

import FormContatti from "../../components/form-contatti";

import Square from "../../svg/square-det.svg";
import SquareImgDx from "../../svg/square-img-dx.svg";
import SquareImgSx from "../../svg/square-img-sx.svg";

const hero = [
  {
    title: "Liferay",
    description: "Soluzioni su misura, sicure e connesse su un'unica piattaforma",
  },
];
const breadcrumb = [
  { title: "Home", link: "/" },
  { title: "Competence center", link: "/competence-center/" },
  { title: "Liferay" },
];

const Liferay = ({ data, location }) => {
  const { intro, importanza, progetti } = data;

  const scrollRef = useRef();
  const getScrollContainer = useCallback(() => scrollRef.current, []);

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo
        title={`Liferay`}
        description={`Liferay è la tecnologia ideale per la realizzazione di website, portali per la comunicazione interna e esterna, intranet, siti eCommerce B2B. Scopri i vantaggi!`}
      />

      <div className="section section--blue-light-2">
        <Square className="square d-none d-md-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
              <p>
                Per la realizzazione di portali di successo, Liferay è la risposta tecnologicamente
                più avanzata e vincente che Ariadne mette in campo per rispondere con soluzioni
                personalizzate alle esigenze di imprese di tutto il mondo. Affidabile e flessibile,
                Liferay è la tecnologia ideale per la realizzazione di siti web, portali per la
                comunicazione interna ed esterna, intranet, siti eCommerce B2B, avvalendosi di un
                CMS senza pari, con vantaggi competitivi unici al mondo.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-4 col-xl-7 offset-xl-5 mb-0 mb-xl-5">
              <div className="section__img-intro">
                <SquareImgDx className="square-top" />
                <SquareImgSx className="square-bottom" />
                <GatsbyImage image={getImage(intro)} alt="Image intro" className="w-100" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-xl-4 d-none d-md-block">
              <AutoAffix
                affixClassName="sidebar sidebar--affix"
                topClassName="sidebar sidebar--affix-top"
                bottomClassName="sidebar sidebar--affix-bottom"
                viewportOffsetTop={60}
                container={getScrollContainer}
              >
                <div>
                  <nav className="anchor-nav">
                    <Scrollspy
                      items={[
                        "cose-liferay-una-piattaforma-infinite-soluzioni",
                        "liferay-dpx-la-piattaforma-di-esperienza-digitale-flessibile-e-integrata",
                        "limportanza-di-scegliere-un-partner-con-certificazione-liferay",
                        "progetti-realizzati-su-liferay-dxp",
                      ]}
                      offset={-60}
                      className="anchor-nav__list"
                      currentClassName="anchor-nav__item--active"
                    >
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#cose-liferay-una-piattaforma-infinite-soluzioni`}
                          to={`cose-liferay-una-piattaforma-infinite-soluzioni`}
                          className="anchor-nav__link"
                          title="Cos'è Liferay? Una piattaforma, infinite soluzioni"
                        >
                          Cos'è Liferay? Una piattaforma, infinite soluzioni
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#liferay-dpx-la-piattaforma-di-esperienza-digitale-flessibile-e-integrata`}
                          to={`liferay-dpx-la-piattaforma-di-esperienza-digitale-flessibile-e-integrata`}
                          className="anchor-nav__link"
                          title="Liferay DPX: la piattaforma di esperienza digitale flessibile e integrata"
                        >
                          Liferay DPX: la piattaforma di esperienza digitale flessibile e integrata
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#limportanza-di-scegliere-un-partner-con-certificazione-liferay`}
                          to={`limportanza-di-scegliere-un-partner-con-certificazione-liferay`}
                          className="anchor-nav__link"
                          title="L'importanza di scegliere un Partner con certificazione Liferay"
                        >
                          L'importanza di scegliere un Partner con certificazione Liferay
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#progetti-realizzati-su-liferay-dxp`}
                          to={`progetti-realizzati-su-liferay-dxp`}
                          className="anchor-nav__link"
                          title="Progetti realizzati su Liferay DXP"
                        >
                          Progetti realizzati su Liferay DXP
                        </ScrollLink>
                      </li>
                    </Scrollspy>
                  </nav>
                </div>
              </AutoAffix>
            </div>
            <div className="col-md-8 offset-md-1 col-xl-7 offset-xl-1 mb-5">
              <div className="anchor-text" ref={scrollRef}>
                <div id="cose-liferay-una-piattaforma-infinite-soluzioni" className="anchor">
                  <h2 className="anchor-text__title">
                    Cos'è Liferay? Una piattaforma, infinite soluzioni
                  </h2>
                  <p>
                    <strong>Liferay DXP: one platform, endless solutions.</strong>
                  </p>
                  <p>
                    Leader tra i software Open Source in ambito Digital Experience Platform,{" "}
                    <a
                      href="https://www.liferay.com/it/home"
                      title="Liferay"
                      rel="noopener noreferrer"
                    >
                      Liferay
                    </a>{" "}
                    rappresenta una soluzione vincente per rispondere alle esigenze di
                    organizzazioni medie e grandi in fatto di portali web. Rappresenta l'alternativa
                    più concreta e credibile alle suite enterprise proprietarie.
                  </p>
                  <p>
                    Sfruttando il potenziale di Liferay, Ariadne ha la possibilità di garantire la
                    soluzione ideale per ogni progetto, andando a realizzare sistemi digitali
                    personalizzati, progettati sulla base delle esigenze degli utenti di
                    riferimento, attraverso funzionalità flessibili e totalmente customizzabili.
                  </p>
                  <p>
                    L'ampio range di funzionalità pronte all'uso e l'architettura flessibile e
                    aperta, studiata per potersi espandere secondo le necessità del business,
                    rendono Liferay DXP un prodotto in grado di unire innovazione tecnologica ed
                    elevati standard di usabilità per l'utente.
                  </p>
                  <p>Le principali funzionalità della piattaforma riguardano:</p>
                  <ul>
                    <li>Gestione Identità e Controllo Accessi (Application Security)</li>
                    <li>Servizi di Piattaforma</li>
                    <li>Integrazione e interoperabilità</li>
                    <li>Collaboration e Social</li>
                    <li>Gestione Contenuti</li>
                    <li>Gestione User Esperience</li>
                    <li>Segmentazione &amp; Personalizzazione</li>
                    <li>Supporto Multicanale</li>
                    <li>Analytic e Ottimizzazione</li>
                  </ul>
                  <p>
                    La Digital Experience Platform di Liferay, riconosciuta leader nel{" "}
                    <a
                      href="https://www.liferay.com/it/company/gartner/magic-quadrant-digital-experience-platforms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Magic Quadrant di Gartner
                    </a>{" "}
                    per il decimo anno consecutivo, aiuta le aziende nella creazione di esperienze
                    rivolte a molti e diversi target di utenti.{" "}
                  </p>
                  <p>
                    Condividiamo di seguito alcuni esempi di impiego che Liferay mette a
                    disposizione:{" "}
                  </p>
                  <p>
                    <strong>Digital Workplace e Intranet</strong>
                  </p>
                  <p>
                    Per fornire a dipendenti e collaboratori delle aziende strumenti smart per
                    facilitare il lavoro, ottimizzare i processi e migliorare la comunicazione
                    interna.
                  </p>
                  <ul>
                    <li>Moduli e flussi di lavoro</li>
                    <li>Distribuzione di contenuti basata sui ruoli</li>
                    <li>Codifica automatica delle risorse</li>
                    <li>Condivisione dei contenuti del sito</li>
                  </ul>
                  <p>
                    <strong>Customer Portals</strong>
                  </p>
                  <p>Per comunicare in modo veloce ed efficace con clienti e partners:</p>
                  <ul>
                    <li>Distribuzione mirata dei contenuti</li>
                    <li>Dashboard personalizzati</li>
                    <li>Funzionalità self-service</li>
                    <li>Interfaccia utente personalizzabile</li>
                  </ul>
                  <p>
                    <strong>Integration Platform</strong>
                  </p>
                  <p>
                    Con Liferay è possibile integrare tutti i sistemi aziendali, nuovi o
                    preesistenti, in una unica potente piattaforma.
                  </p>
                  <ul>
                    <li>Framework di integrazione pronto per l'uso</li>
                    <li>Modularità OGSi</li>
                    <li>Applicazioni personalizzate e strumenti per sviluppatori</li>
                    <li>Alta disponibilità e scalabilità</li>
                  </ul>
                  <p>
                    <strong>Siti di E-commerce B2B</strong>
                  </p>
                  <p>
                    Grazie a Liferay Commerce è possibile predisporre un sistema di vendite online
                    progettato con logiche B2B-first che preveda:
                  </p>
                  <ul>
                    <li>Gestione catalogo e prodotto</li>
                    <li>Funzionalità self-service</li>
                    <li>Supporto per la rete di vendita e assistenza</li>
                    <li>Prezzi e sconti</li>
                  </ul>
                  <p>
                    <strong>Partner Portal</strong>
                  </p>
                  <p>
                    Lo strumento ideale per comunicare in modo completo ed efficace con i partner.
                    Una piattaforma ad hoc costruita per gestire scenari complessi.
                  </p>
                  <ul>
                    <li>Collaborazione sociale</li>
                    <li>Multitenancy</li>
                    <li>Localizzazione e supporto multilingue</li>
                    <li>Ruoli utente ad elevata granularità</li>
                  </ul>
                  <p>
                    <strong>Siti web</strong>{" "}
                  </p>
                  <p>
                    Attraverso Liferay DXP è possibile creare esperienze web personalizzate per
                    attirare prospect e fidelizzare i clienti
                  </p>
                  <ul>
                    <li>Forte integrazione DAM ed Elasticsearch</li>
                    <li>Creazione di esperienze Web In-Context</li>
                    <li>Architettura disaccoppiata e API headless</li>
                    <li>Contenuti raccomandati</li>
                  </ul>
                </div>
                <div
                  id="liferay-dpx-la-piattaforma-di-esperienza-digitale-flessibile-e-integrata"
                  className="anchor"
                >
                  <h2 className="anchor-text__title">
                    Liferay DPX: la piattaforma di esperienza digitale flessibile e integrata
                  </h2>
                  <p>
                    La Digital Experience Platform di Liferay aiuta le aziende a costruire sistemi
                    digitali su misura come Digital Workplace, Customer Portals,{" "}
                    <Link
                      to="/competence-center/liferay/liferay-commerce/"
                      title="Ariadne - E-commerce"
                    >
                      siti di E-Commerce B2B e B2C
                    </Link>
                    , siti web, ecc. Costruita su un'architettura altamente flessibile con{" "}
                    <Link to="/competence-center/cloud-applications/" title="Ariadne - CMS">
                      CMS
                    </Link>
                    , portali e capacità di collaborazione, Liferay DXP è progettata per funzionare
                    perfettamente all'interno di processi e sistemi aziendali esistenti per creare
                    soluzioni personalizzate che regalano esperienze migliori per clienti,
                    dipendenti e partner. Tutto su un’unica piattaforma. In definitiva, adottare la
                    tecnologia di Liferay ci garantisce:
                  </p>
                  <p>
                    <strong>Una piattaforma flessibile per ogni esigenza</strong>
                  </p>
                  <p>
                    Liferay DXP è pensata per integrarsi al meglio con processi e tecnologie già
                    esistenti e progettare nuove esperienze utente personalizzate in grado di
                    soddisfare al meglio le differenti esigenze di business.{" "}
                  </p>
                  <p>
                    <strong>Facilità di integrazione</strong>
                  </p>
                  <p>
                    Per unificare le varie esperienze e sistemi in una unica e potente piattaforma
                    che facilita la gestione e l’hosting.{" "}
                  </p>
                  <p>
                    <strong>User Experience Personalizzata</strong>
                  </p>
                  <p>
                    Liferay DXP semplifica la creazione e l'erogazione di esperienze che consentono
                    di soddisfare le aspettative dei clienti.{" "}
                  </p>
                  <p>
                    Dal canale YouTube di Liferay, la presentazione di Liferay Digital Experience
                    Platform:
                  </p>
                  <div className="iframe-responsive">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/HOdbzGCI5ME"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen=""
                      title="Liferay"
                    ></iframe>
                  </div>
                </div>
                <div
                  id="limportanza-di-scegliere-un-partner-con-certificazione-liferay"
                  className="anchor"
                >
                  <GatsbyImage
                    image={getImage(importanza)}
                    alt="Image importanza"
                    className="anchor-text__img"
                  />
                  <h2 className="anchor-text__title">
                    L'importanza di scegliere un Partner con certificazione Liferay
                  </h2>
                  <p>
                    “Power is nothing without control” è lo slogan coniato ormai 25 anni fa da un
                    famoso brand italiano. Lo riproponiamo in questo contesto poiché anche la
                    tecnologia Liferay, come ogni strumento potente, per esprimersi al meglio deve
                    essere maneggiata da player abili e competenti in materia. A maggior ragione
                    quando si ha a che fare con questo tipo di soluzioni di classe Enterprise, è
                    fondamentale affidarsi a partner tecnologici specializzati.
                  </p>
                  <p>
                    <strong>
                      Ariadne sviluppa su Liferay dal 2006, e dal 2010 è Service Partner
                      certificato.
                    </strong>{" "}
                    Nel 2014 ha raggiunto il più elevato grado di partnership{" "}
                    <strong>
                      e in questi anni si è sempre confermata Liferay Platinum Partner.
                    </strong>{" "}
                    Con oltre 150 progetti realizzati per circa 200 anni/uomo di sviluppo cumulati
                    nel tempo,{" "}
                    <strong>
                      Ariadne vanta uno dei Competence Center Liferay più estesi d’Europa con oltre
                      30 risorse specialistiche che sviluppano esclusivamente su questa tecnologia.
                    </strong>
                  </p>
                  <p>
                    Grazie a questo know-how e all’esperienza consolidata, Ariadne è stato il
                    partner di molti progetti di successo per numerose imprese e si propone di
                    sostenere sempre nuovi Clienti nel processo di digitalizzazione di alto livello.
                  </p>
                </div>
                <div id="progetti-realizzati-su-liferay-dxp" className="anchor">
                  <GatsbyImage
                    image={getImage(progetti)}
                    alt="Image progetti"
                    className="anchor-text__img"
                  />
                  <h2 className="anchor-text__title">Progetti realizzati su Liferay DXP</h2>
                  <p>
                    In questi anni abbiamo realizzato su Liferay oltre 150 progetti per grandi e
                    medie realtà, italiane ed internazionali, che hanno scelto il team altamente
                    qualificato di Ariadne per lo sviluppo dei loro progetti. Ecco una sintesi dei
                    principali.{" "}
                  </p>
                  <p>
                    <Link to="/progetti/" title="Progetti Liferay">
                      Guarda i nostri progetti su Liferay
                    </Link>
                  </p>
                  <p>
                    Per ricevere una consulenza gratuita sulle possibilità di sviluppo di un portale
                    corporate con Liferay,{" "}
                    <Link to="/contatti/" title="Ariadne - Contatti">
                      contattaci senza impegno
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormContatti location={location} />
    </Layout>
  );
};

export default Liferay;

export const query = graphql`
  query {
    intro: file(relativePath: { eq: "pages/liferay.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    importanza: file(relativePath: { eq: "pages/liferay-importanza.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    progetti: file(relativePath: { eq: "pages/liferay-progetti.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
  }
`;
